import * as React from 'react';

const SvgIconPolicies12 = (props) => (
  <svg viewBox="0 0 40 46" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.671 19.355a.664.664 0 0 0-.671.657c0 .364.3.658.671.658H2.54c.37 0 .671-.294.671-.658 0-.363-.3-.657-.671-.657H.67ZM39.259 19.355h-1.87a.664.664 0 0 0-.67.657c0 .364.3.658.67.658h1.87c.37 0 .67-.294.67-.658 0-.363-.3-.657-.67-.657ZM5.456 28.222a.68.68 0 0 0-.917-.24l-1.618.915a.651.651 0 0 0-.246.899.678.678 0 0 0 .917.24l1.618-.915a.65.65 0 0 0 .246-.899ZM36.339 9.988l-1.619.915a.65.65 0 0 0-.245.899.679.679 0 0 0 .917.24l1.618-.915a.65.65 0 0 0 .245-.899.679.679 0 0 0-.916-.24ZM29.26 5.55l.934-1.586a.65.65 0 0 0-.246-.898.679.679 0 0 0-.917.24l-.934 1.587a.65.65 0 0 0 .245.898.679.679 0 0 0 .917-.24Z"
      fill="#E81528"
    />
    <mask
      id="icon-policies-12_svg__a"
      style={{
        maskType: 'alpha'
      }}
      maskUnits="userSpaceOnUse"
      x={19}
      y={0}
      width={2}
      height={4}>
      <path d="M19.294.444h1.342v3.147h-1.342V.444Z" fill="#fff" />
    </mask>
    <g mask="url(#icon-policies-12_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.636 2.934V1.102c0-.363-.3-.658-.67-.658a.665.665 0 0 0-.672.658v1.832c0 .363.3.657.671.657.371 0 .671-.294.671-.657Z"
        fill="#E81528"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.67 5.55a.678.678 0 0 0 .918.241.65.65 0 0 0 .245-.898L10.9 3.307a.679.679 0 0 0-.917-.241.651.651 0 0 0-.246.898l.935 1.587ZM36.338 30.037a.679.679 0 0 0 .917-.24.65.65 0 0 0-.245-.9l-1.619-.915a.679.679 0 0 0-.916.24.65.65 0 0 0 .245.9l1.618.915ZM5.21 10.903l-1.618-.915a.679.679 0 0 0-.917.24.651.651 0 0 0 .246.9l1.618.915a.68.68 0 0 0 .917-.241.651.651 0 0 0-.246-.899ZM20.922 6.604a.66.66 0 0 0 .59.73c6.04.69 10.795 5.3 11.565 11.21a.667.667 0 0 0 .75.57.66.66 0 0 0 .581-.737c-.843-6.474-6.123-11.594-12.741-12.35a.669.669 0 0 0-.745.577Z"
      fill="#E81528"
    />
    <mask
      id="icon-policies-12_svg__b"
      style={{
        maskType: 'alpha'
      }}
      maskUnits="userSpaceOnUse"
      x={5}
      y={6}
      width={30}
      height={40}>
      <path d="M5.403 6.009h29.058v39.34H5.403V6.01Z" fill="#fff" />
    </mask>
    <g mask="url(#icon-policies-12_svg__b)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.835 35.193v1.056c0 .785-.62 1.518-1.617 1.518h-10.57c-.856 0-1.552-.682-1.552-1.52v-1.054h13.739Zm-2.288 6.435a.029.029 0 0 1-.03.029h-9.094a.039.039 0 0 1-.039-.039v-2.535h9.163v2.545Zm-2.545 1.866c0 .298-.247.54-.55.54h-2.956a.564.564 0 0 1-.568-.557v-.505h4.074v.522Zm-6.876-18.626 4.168 2.593v3.317c-2.161-1.386-3.632-3.498-4.168-5.91Zm4.168-10.017v5.664l-3.14-2.397a9.58 9.58 0 0 1 3.14-3.267Zm4.474 3.254-3.132 2.39v-5.644a9.578 9.578 0 0 1 3.132 3.254Zm-3.132 12.673v-3.333l4.172-2.595c-.532 2.419-2.005 4.539-4.172 5.928Zm4.387-7.62-4.387 2.729v-3.724l3.723-2.842a9.391 9.391 0 0 1 .663 3.837Zm-9.458-3.822 3.729 2.846v3.72l-4.385-2.727a9.393 9.393 0 0 1 .656-3.84Zm-10.159.587c-.079 4.033 1.597 7.928 4.597 10.687 1.112 1.024 1.75 2.454 1.75 3.925v1.712c0 1.36.983 2.499 2.288 2.773v2.598c0 .747.62 1.354 1.382 1.354h1.163v.505c0 1.032.857 1.872 1.91 1.872h2.956c1.043 0 1.893-.832 1.893-1.855v-.522h1.172c.756 0 1.372-.603 1.372-1.344V39.02c1.306-.273 2.288-1.411 2.288-2.771v-1.714c0-1.471.638-2.901 1.75-3.924 2.581-2.374 4.19-5.576 4.53-9.017a.661.661 0 0 0-.603-.718.667.667 0 0 0-.733.592c-.308 3.122-1.77 6.029-4.113 8.184a6.702 6.702 0 0 0-2.14 4.225h-6.232v-1.605a2.07 2.07 0 0 0 .388-.179c7.126-4.217 7.112-14.349 0-18.557a2.08 2.08 0 0 0-2.118 0c-3.545 2.098-5.544 5.9-5.33 9.852a.648.648 0 0 0 .027.375c.313 3.503 2.32 6.565 5.303 8.33.125.074.255.133.388.179v1.605h-6.232a6.705 6.705 0 0 0-2.14-4.226c-2.724-2.505-4.245-6.041-4.174-9.703.127-6.51 5.192-11.938 11.78-12.627a.661.661 0 0 0 .597-.724.668.668 0 0 0-.739-.584c-7.224.755-12.838 6.639-12.98 13.91Z"
        fill="#E81528"
      />
    </g>
  </svg>
);

export default SvgIconPolicies12;
