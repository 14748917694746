import * as React from 'react';

const SvgIconPolicies3 = (props) => (
  <svg viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.387 34.43c-.2 0-.397.081-.539.223a.767.767 0 0 0-.223.538c0 .2.082.397.223.539a.767.767 0 0 0 .539.223c.2 0 .397-.081.538-.223a.768.768 0 0 0 .223-.539c0-.2-.081-.397-.223-.538a.767.767 0 0 0-.538-.223Z"
      fill="#E81528"
    />
    <mask
      id="icon-policies-3_svg__a"
      style={{
        maskType: 'alpha'
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={39}
      height={39}>
      <path d="M0 0h38.999v39H0V0Z" fill="#fff" />
    </mask>
    <g mask="url(#icon-policies-3_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m37.11 13.233-.441.44-1.772-1.771.44-.44a1.254 1.254 0 0 1 1.773 0 1.254 1.254 0 0 1 0 1.77Zm-2.84 2.839-5.547 5.547-1.771-1.772 6.868-6.868 1.772 1.772-1.321 1.32Zm-6.086 21.405H2.59a1.068 1.068 0 0 1-1.067-1.067V2.59c0-.588.479-1.067 1.067-1.067H8.9v1.524H3.808a.762.762 0 0 0-.762.762V35.19c0 .421.34.762.762.762h8.53a.762.762 0 1 0 0-1.523H4.57V4.57H8.9v.057a2.383 2.383 0 0 0 2.38 2.38h8.214a2.383 2.383 0 0 0 2.38-2.38V4.57h4.33v13.871l-.869.868a.772.772 0 0 0-.157.229l-2.286 5.135a.762.762 0 0 0 1.006 1.006l2.305-1.027v9.778h-7.77a.762.762 0 1 0 0 1.523h8.532c.42 0 .762-.34.762-.762V23.974l1.306-.582a.76.76 0 0 0 .217-.146V36.41c0 .588-.478 1.067-1.066 1.067ZM20.35 1.523v3.104a.858.858 0 0 1-.857.857H11.28a.858.858 0 0 1-.857-.857V1.523h9.928Zm4.74 21.957 1.028-2.31 1.282 1.281-2.31 1.029ZM29.25 2.59v12.805l-1.523 1.523V3.808a.762.762 0 0 0-.762-.761h-5.09V1.523h6.309c.588 0 1.066.479 1.066 1.067Zm8.937 7.794a2.78 2.78 0 0 0-3.926 0l-3.488 3.487V2.59A2.593 2.593 0 0 0 28.183 0H2.59A2.593 2.593 0 0 0 0 2.59v33.82A2.593 2.593 0 0 0 2.59 39h25.594a2.593 2.593 0 0 0 2.59-2.59V21.724l7.413-7.414a2.78 2.78 0 0 0 0-3.926Z"
        fill="#E81528"
      />
    </g>
    <mask
      id="icon-policies-3_svg__b"
      style={{
        maskType: 'alpha'
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={39}
      height={39}>
      <path d="M0 39h38.999V0H0v39Z" fill="#fff" />
    </mask>
    <g mask="url(#icon-policies-3_svg__b)" fillRule="evenodd" clipRule="evenodd" fill="#E81528">
      <path d="M8.227 14.016h3.351v-3.352H8.227v3.352ZM12.34 9.14H7.465a.762.762 0 0 0-.762.761v4.875c0 .421.341.762.762.762h4.875c.42 0 .762-.341.762-.762V9.902a.762.762 0 0 0-.762-.761ZM8.227 22.395h3.351v-3.352H8.227v3.352Zm4.113-4.875H7.465a.762.762 0 0 0-.762.761v4.875c0 .42.341.762.762.762h4.875c.42 0 .762-.341.762-.762v-4.875a.762.762 0 0 0-.762-.761ZM8.227 30.773h3.351v-3.351H8.227v3.351Zm4.113-4.875H7.465a.762.762 0 0 0-.762.762v4.875c0 .42.341.762.762.762h4.875c.42 0 .762-.341.762-.762V26.66a.762.762 0 0 0-.762-.762ZM17.792 14.598a.76.76 0 0 0 1.077 0l3.439-3.44a.762.762 0 0 0-1.078-1.076l-2.9 2.9-1.156-1.156a.762.762 0 0 0-1.077 1.077l1.695 1.695ZM17.792 22.976a.762.762 0 0 0 1.077 0l3.439-3.438a.762.762 0 0 0-1.078-1.078l-2.9 2.9-1.156-1.155a.762.762 0 0 0-1.077 1.077l1.695 1.694ZM19.203 30.175l1.18 1.18a.76.76 0 0 0 1.078 0 .761.761 0 0 0 0-1.077l-1.18-1.18 1.18-1.181a.761.761 0 1 0-1.077-1.078l-1.181 1.181-1.181-1.18a.762.762 0 0 0-1.077 1.076l1.18 1.181-1.18 1.181a.762.762 0 0 0 1.077 1.078l1.18-1.181Z" />
    </g>
  </svg>
);

export default SvgIconPolicies3;
