import React from 'react';
import MainNavigation from 'components/__molecules/main-navigation';
import Section from 'components/__atoms/section';
import i18n from 'utils/i18n';
// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Organism Header
 *
 * The portal header component
 */
function Header(props) {
  const { className = '', menuData = null, isRendering, fullpath, ...other } = props;

  const gt = i18n.useTranslations('global');

  return (
      <header className={`${css['header-wrapper']} ${className}`} data-is-rendering={isRendering ? 1 : null} {...other}>
        <Section tag="div" className={css['header-container']}>
          {fullpath === '/' ? (
            <h1 className={css['header-logo']}>
              <img className={css['header-logo__image']} src="/images/logos/logo-kinto.svg" alt="Toyota Kinto" />
            </h1>
          ) : (
            <a href="/" title={gt('back-to-home')} className={css['header-logo__anchor']}>
              <img className={css['header-logo__image']} src="/images/logos/logo-kinto.svg" alt="Toyota Kinto" />
              <span data-visuallyhidden>{`${gt('back-to-home')} Toyota`}</span>
            </a>
          )}
          <div className={css['header-navigation']}>
            {menuData && <MainNavigation menuData={menuData} />}
          </div>
        </Section>
      </header>
  );
}

export default Header;
