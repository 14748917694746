import AnchorLink from '../../__atoms/anchor-link';
import React, { useState } from 'react';

import Wrapper from '../../__atoms/wrapper';
import Section from '../../__atoms/section';
import { IconClose } from '../../__icons';
import i18n from '../../../utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';

// import {menuData} from 'utils/menu';

/**
 * Molecule MainNavigation
 *
 * The portal main navigation
 */
function MainNavigation(props) {
  const { className = '', menuData = [], ...other } = props;

  const t = i18n.useTranslations('components.main_navigation');

  const menuParents = getMenuParents();

  const [isMenuActive, SetIsMenuActive] = useState(false);
  const [isMenuMobileActive, SetIsMenuMobileActive] = useState(false);
  const [activeMenuCategory, SetActiveMenuCategory] = useState(null);

  function getMenuParents() {
    const menuParents = menuData
      .sort((a, b) => a.order - b.order)
      .filter(function (item) {
        return !item.parent;
      });

    return menuParents;
  }

  function updateMenuCategory(evt, categoryId) {
    evt.preventDefault();

    activeMenuCategory === categoryId ? closeMenu() : (SetActiveMenuCategory(categoryId), SetIsMenuActive(true));
  }

  function getCategoryChildren(id, verifyHighlight = false) {
    return menuData
      .filter(function (item) {
        return item?.parent?.id === id && item?.status === 'ENABLED' && item?.highlight === verifyHighlight;
      })
      .sort((a, b) => a.order - b.order);
  }

  function closeMenu() {
    SetActiveMenuCategory(null);
    SetIsMenuActive(false);
  }

  function mobileNavigationToggler() {
    SetIsMenuMobileActive(!isMenuMobileActive);
    if (isMenuMobileActive) {
      closeMenu();
    }
  }

  function isValidLink(item) {
    return item?.status === 'ENABLED' && item?.target?.url && item?.label && item?.label.trim() !== '';
  }

  return (
    <>
      <nav
        className={`${css['main-navigation']} ${className}`}
        {...other}
        id="navigation"
        data-is-menu-active={isMenuActive}
        data-is-mobile-menu-active={isMenuMobileActive}
      >
        <button className={css['navigation-toogler']} onClick={mobileNavigationToggler}>
          <span className={css['navigation-toogler__burguer']}>
            <span data-visuallyhidden>Menu</span>
          </span>
        </button>
        {menuParents && menuParents.length > 1 && (
          <ul className={css['navigation-list']}>
            {menuParents
              .filter(isValidLink)
              .map((item) => {
               const hasChildren = getCategoryChildren(item.id).length > 0;
              return (
                <li key={item?.id} className={css['navigation-list__item']}>
                  <AnchorLink
                    color="primary"
                    className={css['navigation-list__action']}
                    link={item?.target?.url}
                    anchorText={item?.target?.anchorText}
                    hasArrow = {hasChildren}
                    arrowPosition={hasChildren ? (activeMenuCategory === item?.id ? 'up' : 'down') : null}
                    linkLabel={item?.label}
                    size="medium"
                    removeUnderline
                    onClick={(evt) => {
                      if (hasChildren) {
                        updateMenuCategory(evt, item?.id);
                      }
                    }}
                  />
                </li>
              );
            })}
          </ul>
        )}

        <Wrapper style={{ display: isMenuActive && menuParents.length > 1  ? 'block' : 'none' }} className={css['sub-navigation-wrapper']}>
          <Section className={css['sub-navigation-content']} tag="div">
            <AnchorLink
              color="primary"
              linkLabel={ isMenuMobileActive ? t('previous-menu'): t('previous-page')}
              removeUnderline
              invertArrow
              arrowPosition="left"
              onClick={closeMenu}
              data-hide="desktop"
              className={css['sub-navigation__back-button']}
            />
            {menuParents
            .filter(isValidLink)
            .map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <ul
                    style={{ display: activeMenuCategory === item?.id ? 'block' : 'none' }}
                    className={css['sub-navigation-content-list']}
                  >
                    {getCategoryChildren(item.id).map((item) => {
                      return (
                        <React.Fragment key={item.id}>
                          <li className={css['sub-navigation-content-list__item']}>
                            <strong className={css['sub-navigation__title']}>{item.label}</strong>
                            {getCategoryChildren(item.id) && (
                              <ul className={css['sub-navigation-list']}>
                                {getCategoryChildren(item.id)
                                .filter(isValidLink)
                                .map((item) => {
                                  return (
                                    <React.Fragment key={item.id}>
                                      {item?.label  && item?.target?.url &&
                                        <li key={item.id} className={css['sub-navigation-list__item']}>
                                        <AnchorLink
                                          color="primary"
                                          link={item?.target?.url}
                                          anchorText={item?.target?.anchorText}
                                          linkLabel={item?.label}
                                          removeUnderline
                                          hasArrow={false}
                                          onClick={() => {
                                            mobileNavigationToggler();
                                          }}
                                        />
                                      </li>
                                    }
                                    </React.Fragment>
                                  );
                                })}
                              </ul>
                            )}
                            {getCategoryChildren(item.id, true).length > 0 && (
                              <ul className={css['sub-navigation-extra-list']}>
                                {getCategoryChildren(item.id, true)
                                 .filter(isValidLink)
                                 .map((item) => {
                                  return (
                                    <React.Fragment key={item.id}>
                                      {item?.label && item?.target?.url &&
                                        <li key={item.id} className={css['sub-navigation-extra-list__item']}>
                                          <AnchorLink
                                            color="primary"
                                            link={item?.target?.url}
                                            anchorText={item?.target?.anchorText}
                                            linkLabel={item?.label}
                                            onClick={() => {
                                              mobileNavigationToggler();
                                            }}
                                          />
                                        </li>
                                      }
                                    </React.Fragment>
                                  );
                                })}
                              </ul>
                            )}
                          </li>
                        </React.Fragment>
                      );
                    })}
                  </ul>
                </React.Fragment>
              );
            })}
            <button className={css['close-button']} data-hide="mobile" onClick={closeMenu}>
              <IconClose className={css['close-button__icon']} />
              <span data-visuallyhidden>Fechar menu</span>
            </button>
          </Section>
        </Wrapper>
      </nav>
    </>
  );
}

export default MainNavigation;
