import * as React from 'react';

const SvgIconPolicies6 = (props) => (
  <svg viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m5.2 7.531-.84-.84-.92.919L5.2 9.37l3.06-3.06-.92-.92L5.2 7.532ZM10.4 6.5h9.75V5.2H10.4v1.3ZM10.4 9.1h9.75V7.8H10.4v1.3ZM10.4 22.1h11.7v-1.3H10.4v1.3ZM10.4 24.7h11.7v-1.3H10.4v1.3ZM7.34 20.34l-1.49 1.491-1.49-1.49-.92.919 1.49 1.49-1.49 1.49.92.92 1.49-1.49 1.49 1.49.92-.92-1.49-1.49 1.49-1.49-.92-.92ZM10.4 29.9h11.05v-1.3H10.4v1.3ZM10.4 32.5h11.05v-1.3H10.4v1.3ZM7.34 28.14l-1.49 1.491-1.49-1.49-.92.919 1.49 1.49-1.49 1.49.92.92 1.49-1.49 1.49 1.49.92-.92-1.49-1.49 1.49-1.49-.92-.92ZM5.2 15.33l-.84-.84-.92.92 1.76 1.76 3.06-3.06-.92-.92-2.14 2.14ZM10.4 14.3h14.3V13H10.4v1.3ZM10.4 16.9h14.3v-1.3H10.4v1.3Z"
      fill="#E81528"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.7 37.7h-13v-9.75a4.554 4.554 0 0 1 4.16-4.53l1.018 2.036-1.32 8.57L31.2 36.67l2.642-2.642-1.318-8.571 1.016-2.036a4.554 4.554 0 0 1 4.16 4.53v9.75ZM1.3 36.4V1.3h20.8v6.5h6.5v4.55h-1.95v4.55a4.545 4.545 0 0 0 1.95 3.728v1.51a5.857 5.857 0 0 0-5.2 5.812v8.45H1.3ZM23.4 2.22l4.28 4.28H23.4V2.22Zm11.016 14.345A7.183 7.183 0 0 0 29.9 14.95h-1.95v-1.3h3.25a3.243 3.243 0 0 1 3.216 2.915ZM31.2 20.15a3.254 3.254 0 0 1-3.25-3.25v-.65h1.95a5.88 5.88 0 0 1 4.301 1.892A3.254 3.254 0 0 1 31.2 20.15Zm1.258 13.423L31.2 34.831l-1.258-1.258L31.2 25.4l1.258 8.173ZM31.2 25.197l-.898-1.797h1.796l-.898 1.797Zm1.95-3.097H29.9v-.845a4.55 4.55 0 1 0 1.3-8.905h-1.3V6.88L23.02 0H0v37.7h23.4V39H39V27.95a5.856 5.856 0 0 0-5.85-5.85Z"
      fill="#E81528"
    />
  </svg>
);

export default SvgIconPolicies6;
