import * as React from 'react';

const SvgIconExpressReview = (props) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.839 2.5h2.21v1.048a7.952 7.952 0 0 0-4.759 1.97l-1.501-1.5-.707.707 1.501 1.501a7.952 7.952 0 0 0-.732 9.545L4.662 18.96a1.855 1.855 0 1 0 2.623 2.623l3.24-3.24a7.952 7.952 0 1 0 4.523-14.795V2.5h2.21v-1h-5.42v1Zm-.58 15.108a6.952 6.952 0 1 0-2.681-2.564l2.381-2.382a3.887 3.887 0 0 1 5.389-4.683l.635.306-2.601 2.601a.516.516 0 0 0 .73.73l2.615-2.615.324.573a3.887 3.887 0 0 1-4.416 5.66l-2.375 2.374Zm.518-6.124a2.887 2.887 0 0 1 3.43-2.837l-1.532 1.532a1.516 1.516 0 0 0 2.144 2.144l1.625-1.624a2.89 2.89 0 0 1-3.775 3.496l-.3-.11-6.79 6.79a.855.855 0 1 1-1.21-1.208l6.76-6.76-.13-.31a2.878 2.878 0 0 1-.222-1.113Z"
      fill="#7C7D80"
    />
    <path
      d="M5.065 10.984H1v1h4.065v-1ZM2.355 8.274h3.387v1H2.355v-1ZM5.742 14.032H2.355v1h3.387v-1Z"
      fill="#7C7D80"
    />
  </svg>
);

export default SvgIconExpressReview;
