import * as React from 'react';

const SvgIconPolicies5 = (props) => (
  <svg viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="icon-policies-5_svg__a"
      style={{
        maskType: 'alpha'
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={41}
      height={46}>
      <path d="M0 .09h40.946v45.82H0V.09Z" fill="#fff" />
    </mask>
    <g mask="url(#icon-policies-5_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.134 7.715a21.563 21.563 0 0 1-4.13-.935.67.67 0 1 0-.428 1.273c1.402.469 2.879.803 4.39.994 2.264.285 3.881 2.37 3.603 4.647-.365 2.985-1.033 7.441-2.144 11.716-1.21 4.656-2.63 7.896-4.22 9.63-3.265 3.558-7.36 6.713-10.22 8.733a4.362 4.362 0 0 1-5.024 0c-2.86-2.02-6.954-5.175-10.22-8.734-1.59-1.733-3.01-4.973-4.22-9.629-1.11-4.274-1.778-8.73-2.144-11.716-.278-2.277 1.339-4.362 3.604-4.647 6.53-.824 10.858-4.096 13.34-6.696a2.95 2.95 0 0 1 2.152-.919 2.95 2.95 0 0 1 2.153.919 22.079 22.079 0 0 0 6.14 4.54.67.67 0 1 0 .602-1.2 20.746 20.746 0 0 1-5.767-4.265A4.284 4.284 0 0 0 20.473.09a4.284 4.284 0 0 0-3.128 1.336C15.015 3.867 10.95 6.94 4.812 7.715c-2.998.378-5.139 3.133-4.77 6.142.626 5.127 2.534 17.542 6.706 22.089 3.346 3.646 7.522 6.864 10.435 8.922a5.696 5.696 0 0 0 3.29 1.043 5.7 5.7 0 0 0 3.29-1.043c2.913-2.058 7.09-5.276 10.435-8.922 4.172-4.547 6.08-16.962 6.707-22.09.368-3.008-1.773-5.763-4.771-6.141Z"
        fill="#E81528"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.548 14.83c-.31 2.268-.942 6.504-1.695 9.4-1.417 5.458-2.755 7.22-3.132 7.63-2.257 2.46-6.712 5.918-8.523 7.29a1.204 1.204 0 0 1-1.45 0c-1.812-1.372-6.267-4.83-8.524-7.29-.376-.41-1.714-2.172-3.132-7.63-.752-2.895-1.384-7.132-1.694-9.4-.09-.66.348-1.276.998-1.401 4.669-.9 8.854-2.924 12.439-6.019a.974.974 0 0 1 .638-.235c.228 0 .456.078.637.235 3.586 3.095 7.77 5.12 12.44 6.019.65.125 1.088.74.998 1.401ZM18.954 6.395c-3.405 2.938-7.38 4.862-11.813 5.716a2.595 2.595 0 0 0-2.077 2.9c.314 2.297.955 6.594 1.725 9.555 1.402 5.4 2.758 7.456 3.442 8.2 2.247 2.449 6.416 5.72 8.701 7.453.454.344.997.515 1.54.515.544 0 1.087-.171 1.54-.515 2.286-1.732 6.455-5.004 8.702-7.453.684-.744 2.04-2.8 3.442-8.2.77-2.962 1.411-7.258 1.726-9.555a2.595 2.595 0 0 0-2.078-2.9c-4.433-.854-8.408-2.778-11.813-5.716a2.338 2.338 0 0 0-3.037 0Z"
      fill="#E81528"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.74 21.038c-.505-.411-2.214-1.26-3.437.352-1.223 1.611-.153 3.017.353 3.429l1.952 1.583c.288.234.712.191.947-.097a.67.67 0 0 0-.098-.944l-1.952-1.584c-.227-.184-.723-.785-.158-1.54s1.317-.343 1.544-.158l4.042 3.28a.674.674 0 0 0 .898-.045l5.914-5.842a1.1 1.1 0 0 1 1.552.007 1.094 1.094 0 0 1-.007 1.548l-7.044 6.96c-.558.536-1.172.312-1.465.07l-1.118-.906a.675.675 0 0 0-.948.096.67.67 0 0 0 .098.945l1.118.907c.434.352.982.547 1.542.547.649 0 1.26-.25 1.72-.706l7.044-6.959a2.435 2.435 0 0 0 .016-3.447 2.452 2.452 0 0 0-3.456-.016l-5.484 5.419-3.572-2.9Z"
      fill="#E81528"
    />
  </svg>
);

export default SvgIconPolicies5;
