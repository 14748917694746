import * as React from 'react';

const SvgIconShare = (props) => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.567 8.1V12H3.334V3.767h3.9m0 4.333L12 3.333m0 0H8.967m3.033 0v3.034"
      stroke="#000"
    />
  </svg>
);

export default SvgIconShare;
