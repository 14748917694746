import * as React from 'react';

const SvgIconPolicies10 = (props) => (
  <svg viewBox="0 0 46 47" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.458.796H2.875A2.875 2.875 0 0 0 0 3.67v23a2.875 2.875 0 0 0 2.875 2.875h12.458v-1.917H2.875a.958.958 0 0 1-.958-.958v-23c0-.53.429-.958.958-.958h32.583c.53 0 .959.429.959.958v17.25h1.916V3.67A2.875 2.875 0 0 0 35.458.796Z"
      fill="#E81528"
    />
    <mask
      id="icon-policies-10_svg__a"
      style={{
        maskType: 'alpha'
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={46}
      height={47}>
      <path d="M0 46.796h46v-46H0v46Z" fill="#fff" />
    </mask>
    <g mask="url(#icon-policies-10_svg__a)" fillRule="evenodd" clipRule="evenodd" fill="#E81528">
      <path d="M11.5 34.338h1.917v-5.75H11.5v5.75ZM6.708 23.796a.958.958 0 0 1-.958-.959v-1.916H3.833v1.916a2.875 2.875 0 0 0 2.875 2.875h1.917v-1.916H6.708ZM3.833 19.005H5.75v-1.917H3.833v1.916ZM27.656 36.954a8.496 8.496 0 0 1 .005-16.992 8.434 8.434 0 0 1 6.008 2.492 8.496 8.496 0 0 1-6.013 14.5Zm7.368-15.859a10.413 10.413 0 0 0-7.363-3.05c-5.75 0-10.412 4.662-10.412 10.413a10.412 10.412 0 0 0 17.774 7.363c4.067-4.066 4.067-10.659.001-14.726ZM42.368 44.483l-4.66-4.661 1.318-1.32 4.66 4.662-1.318 1.319Zm3.351-2-6.015-6.013a.958.958 0 0 0-1.355 0l-2.674 2.674a.958.958 0 0 0 0 1.355l6.016 6.017a.958.958 0 0 0 1.355-.003l2.673-2.674a.958.958 0 0 0 0-1.355Z" />
      <path d="m33.669 35.82 1.355-1.356 3.342 3.342-1.355 1.355-3.342-3.342ZM27.792 21.879v1.916a4.798 4.798 0 0 1 4.791 4.792H34.5a6.716 6.716 0 0 0-6.708-6.708ZM9.583 35.295h7.667V33.38H9.583v1.916ZM14.375 8.462a.958.958 0 1 1 0-1.917.958.958 0 0 1 0 1.917Zm0-3.833a2.875 2.875 0 1 0 0 5.75 2.875 2.875 0 0 0 0-5.75ZM21.083 15.171a.958.958 0 1 1 0-1.917.958.958 0 0 1 0 1.917Zm0-3.833a2.875 2.875 0 1 0 0 5.75 2.875 2.875 0 0 0 0-5.75Z" />
      <path d="m7.947 12.577 4.792-4.792 1.355 1.355-4.792 4.792-1.355-1.355ZM14.652 9.143l1.355-1.355 4.791 4.792-1.355 1.355-4.791-4.792ZM27.792 8.462a.958.958 0 1 1 0-1.917.958.958 0 0 1 0 1.917Zm0-3.833a2.875 2.875 0 1 0 0 5.75 2.875 2.875 0 0 0 0-5.75Z" />
      <path d="m21.363 12.58 4.792-4.79 1.355 1.354-4.791 4.792-1.356-1.355Z" />
    </g>
  </svg>
);

export default SvgIconPolicies10;
