import * as React from 'react';

const SvgIconRecall = (props) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m11.354.646-.708.708L11.793 2.5H6A4.5 4.5 0 0 0 1.5 7v10A4.5 4.5 0 0 0 6 21.5h2v-1H6A3.5 3.5 0 0 1 2.5 17V7A3.5 3.5 0 0 1 6 3.5h5.793l-1.147 1.146.708.708L13.707 3 11.354.646ZM16 3.5h2A3.5 3.5 0 0 1 21.5 7v10a3.5 3.5 0 0 1-3.5 3.5h-6.293l1.147-1.146-.708-.707L9.793 21l2.353 2.354.708-.707-1.147-1.147H18a4.5 4.5 0 0 0 4.5-4.5V7A4.5 4.5 0 0 0 18 2.5h-2v1Z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.166 14a.833.833 0 1 0 0 1.667.833.833 0 0 0 0-1.667Zm-1.5.833a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
      fill="#7C7D80"
    />
    <path
      d="m14.566 7.995-.33.376.33-.376ZM7 14H5.5v1H7v-1Zm-1.5 0v-2.5h-1V14h1Zm14-1v1h1v-1h-1Zm0 1H17v1h2.5v-1Zm-14-2.5h1v-1h-1v1Zm1.46-.303.98-2.288-.92-.394-.98 2.288.92.394Zm7.276-2.826 3.435 3.005.658-.752-3.434-3.005-.659.752ZM9.32 8H11.5V7H9.319v1ZM11.5 8h1.748V7H11.5v1Zm-5 3.5h5v-1h-5v1Zm5 0H18v-1h-6.5v1Zm-.5-4V11h1V7.5h-1Zm3.895.119A2.5 2.5 0 0 0 13.249 7v1a1.5 1.5 0 0 1 .987.371l.659-.752ZM19.5 14v1a1 1 0 0 0 1-1h-1Zm1-1a2.5 2.5 0 0 0-2.5-2.5v1a1.5 1.5 0 0 1 1.5 1.5h1ZM7.94 8.91A1.5 1.5 0 0 1 9.32 8V7A2.5 2.5 0 0 0 7.02 8.515l.92.394ZM5.5 11.5v-1a1 1 0 0 0-1 1h1Zm0 2.5h-1a1 1 0 0 0 1 1v-1Z"
      fill="#000"
    />
    <path
      d="M9.17 14.86a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM17.16 14.86a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM9.5 14.5H15"
      stroke="#000"
    />
  </svg>
);

export default SvgIconRecall;
