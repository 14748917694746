import * as React from 'react';

const SvgIconPolicies4 = (props) => (
  <svg viewBox="0 0 46 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#icon-policies-4_svg__a)">
      <mask
        id="icon-policies-4_svg__b"
        style={{
          maskType: 'alpha'
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={6}
        width={12}
        height={13}>
        <path d="M0 6.102h11.032v12.465H0V6.102Z" fill="#fff" />
      </mask>
      <g mask="url(#icon-policies-4_svg__b)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.798 15.769 1.364 6.336a.8.8 0 0 0-1.13 1.13l9.199 9.199-.002 1.101a.8.8 0 0 0 .798.8h.002a.8.8 0 0 0 .799-.797l.002-1.434a.799.799 0 0 0-.234-.566Z"
          fill="#E81528"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.052 9.93h-.002l-1.885.003L6.966.734a.8.8 0 0 0-1.13 1.13l9.433 9.434a.8.8 0 0 0 .565.234h.001l2.218-.003a.8.8 0 0 0-.001-1.599ZM35.084.734A.805.805 0 0 0 34.519.5a.805.805 0 0 0-.565.234.806.806 0 0 0-.234.565c0 .21.085.417.234.565a.806.806 0 0 0 .565.234c.21 0 .416-.085.565-.234a.805.805 0 0 0 .234-.565.806.806 0 0 0-.234-.565ZM32.743 3.075a.8.8 0 0 0-1.13 0l-6.858 6.858-1.102-.002h-.011a7.864 7.864 0 0 0-5.598 2.319l-.718.718a.8.8 0 0 0 1.13 1.13l.718-.718a6.275 6.275 0 0 1 4.468-1.85h.009l1.433.002h.001a.8.8 0 0 0 .565-.234l7.093-7.093a.8.8 0 0 0 0-1.13ZM40.687 6.336a.8.8 0 0 0-1.13 0l-9.434 9.433a.8.8 0 0 0-.234.566l.003 2.218a.8.8 0 0 0 1.599-.002l-.003-1.886 9.199-9.2a.8.8 0 0 0 0-1.13ZM40.686 34.454l-9.2-9.199.002-1.101a7.878 7.878 0 0 0-.604-3.045.8.8 0 1 0-1.476.613c.32.774.483 1.591.482 2.43l-.002 1.432c0 .213.084.416.234.567l9.433 9.433a.798.798 0 0 0 1.13 0 .8.8 0 0 0 0-1.13ZM35.085 40.056l-9.434-9.433a.8.8 0 0 0-.565-.234h-.001l-2.218.003a.8.8 0 0 0 .002 1.598l1.887-.002 9.198 9.199a.797.797 0 0 0 1.13 0 .8.8 0 0 0 0-1.13ZM6.966 40.056a.805.805 0 0 0-.565-.235.805.805 0 0 0-.565.235.805.805 0 0 0-.234.565c0 .21.085.416.234.565a.806.806 0 0 0 .565.234.802.802 0 0 0 .565-1.364Z"
        fill="#E81528"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.583 20.778a2.241 2.241 0 0 0-.605-.43 2.24 2.24 0 0 0 .287-1.101c0-.602-.234-1.167-.66-1.592a2.244 2.244 0 0 0-.644-.45 2.255 2.255 0 0 0-.436-2.57 2.255 2.255 0 0 0-2.569-.437 2.254 2.254 0 0 0-3.636-.644l-3.19 3.19a2.282 2.282 0 0 0-4.17-1.06l-2.188 3.265a7.889 7.889 0 0 0-1.342 4.42l.003 1.886-9.199 9.198a.8.8 0 0 0 1.13 1.13l9.434-9.433a.8.8 0 0 0 .234-.566l-.003-2.217a6.295 6.295 0 0 1 1.07-3.528l2.19-3.265a.68.68 0 0 1 .443-.292.687.687 0 0 1 .712 1.029l-.856 1.411a1.002 1.002 0 0 0 .241 1.323c.41.321.974.287 1.342-.081l5.28-5.28a.654.654 0 0 1 .975.865l-3.124 3.132a.8.8 0 0 0 1.13 1.13l4.04-4.046a.654.654 0 0 1 .923.923l-4.043 4.043a.8.8 0 0 0 1.13 1.13l3.129-3.128a.654.654 0 0 1 .864.976l-3.072 3.072a.8.8 0 1 0 1.13 1.13l2.055-2.054a.654.654 0 0 1 .865.975l-5.708 5.707a6.275 6.275 0 0 1-4.467 1.85h-.009l-1.433-.001h-.001a.8.8 0 0 0-.565.234l-7.426 7.426a.8.8 0 0 0 1.13 1.13l7.191-7.191 1.102.001h.011a7.864 7.864 0 0 0 5.598-2.318l5.707-5.708a2.254 2.254 0 0 0 0-3.184Z"
        fill="#E81528"
      />
    </g>
    <defs>
      <clipPath id="icon-policies-4_svg__a">
        <path fill="#fff" transform="translate(0 .5)" d="M0 0h46v41H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgIconPolicies4;
