import * as React from 'react';

const SvgIconClosedPrice = (props) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.195 10.581H3.79V22.5h16.46V10.581h-3.122m-9.933 0V6.466a4.966 4.966 0 0 1 9.932 0v4.115m-9.932 0h9.933m-4.824 1.703v1.702m0 0c-.757 0-1.987.284-1.987 1.42 0 1.135 1.135 1.418 1.987 1.418.473 0 1.702 0 1.702 1.135 0 1.136-1.135 1.325-1.702 1.42m0-5.393h1.418m-1.418 5.392h-1.987m1.987 0v1.42"
      stroke="#7C7D80"
    />
  </svg>
);

export default SvgIconClosedPrice;
