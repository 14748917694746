import * as React from 'react';

const SvgButtonSearchIcon = (props) => (
  <svg viewBox="0 0 48 48" fill="#EB0A1E"  color="#EB0A1E" xmlns="http://www.w3.org/2000/svg" data-icon="1" {...props}>
    <path fill="#EB0A1E" color="#EB0A1E" d="M0 0h48v48H0z" />
    <path
      d="m32 33.333-4-4M22.667 14.667a8 8 0 1 0 0 16 8 8 0 0 0 0-16Z"
      stroke="#fff"
      strokeWidth={1.5}
    />
  </svg>
);

export default SvgButtonSearchIcon;
