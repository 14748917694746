/* eslint-disable */
import * as React from 'react';

const SvgFile = (props) => (
  <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.563 15.75v.75H39.5v28h-30v-41h19.063v12.25ZM41 44.5V15.75L30.687 2H8v44h33v-1.5ZM38.562 15l-8.5-11.333V15h8.5ZM13.5 16.5H23.81V15H13.5v1.5Zm0 5.5h22v-1.5h-22V22Zm17.875 5.5H13.499V26h17.875v1.5ZM13.499 33h22v-1.5h-22V33Zm17.875 5.5H13.499V37h17.875v1.5Z"
      fill={props?.fill ? props?.fill : "#fff"}
    />
  </svg>
);

export default SvgFile;
/* eslint-enable */
