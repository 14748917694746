import * as React from 'react';

const SvgIcnClose = (props) => (
  <svg
    data-icon="1"
    {...props}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M5.33325 26.6667L26.6666 5.33334M26.6666 26.6667L5.33325 5.33334" stroke="#00708D" strokeWidth="1.33333" />
  </svg>
);

export default SvgIcnClose;
