import * as React from 'react';

const SvgYoutubeIcon = (props) => (
  <svg viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg" data-icon="1" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.738 0h10.524A3.72 3.72 0 0 1 18 3.698v5.204a3.72 3.72 0 0 1-3.738 3.698H3.738A3.72 3.72 0 0 1 0 8.902V3.698A3.72 3.72 0 0 1 3.738 0Zm3.527 8.982 4.63-2.242a.191.191 0 0 0 .004-.338L7.268 4.02a.182.182 0 0 0-.245.076A.195.195 0 0 0 7 4.19v4.623a.194.194 0 0 0 .087.16.182.182 0 0 0 .178.01Z"
      fill="#fff"
      color="#fff"
    />
  </svg>
);

export default SvgYoutubeIcon;
