import * as React from 'react';

const SvgIconPolicies7 = (props) => (
  <svg viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.544 21.512h-.384a.72.72 0 0 0 0 1.44.816.816 0 0 1 0 1.633h-.766a.72.72 0 0 0 0 1.44.816.816 0 0 1 0 1.633h-.767a.72.72 0 0 0 0 1.44.816.816 0 0 1 0 1.633H19.073V23.77a.72.72 0 1 0-1.437 0v7.683a.048.048 0 0 1-.048.047h-4.6a.048.048 0 0 1-.049-.047V19.159c0-.026.022-.048.048-.048h4.6c.027 0 .049.021.049.048v1.537a.72.72 0 1 0 1.437 0v-1.537c0-.035-.002-.07-.005-.104.54-.418.97-.84 1.251-1.14.266-.284.557-.479.865-.579 1.692-.55 2.83-1.436 3.488-2.083.823-.81 1.315-1.655 1.315-2.26 0-.319.125-.619.35-.843.23-.227.532-.347.86-.347.402.004.669.21.822.383.262.296.392.722.346 1.138-.157 1.431-1.3 3.2-2.91 4.507a.721.721 0 0 0 .452 1.28h6.636a1.2 1.2 0 0 1 0 2.4Zm2.636-1.2a2.641 2.641 0 0 0-2.636-2.641H27.7c1.177-1.326 1.946-2.835 2.094-4.19a2.915 2.915 0 0 0-.7-2.252 2.524 2.524 0 0 0-1.885-.867h-.025a2.62 2.62 0 0 0-1.858.765 2.61 2.61 0 0 0-.776 1.854c-.026.154-.296.684-.956 1.313a7.484 7.484 0 0 1-2.854 1.672c-.54.176-1.035.5-1.469.963a9.51 9.51 0 0 1-.99.915 1.473 1.473 0 0 0-.693-.173h-4.6c-.82 0-1.486.668-1.486 1.488v12.293c0 .82.666 1.488 1.485 1.488h4.6c.56 0 1.046-.31 1.3-.768h11.74a2.257 2.257 0 0 0 1.939-3.404 2.258 2.258 0 0 0 .767-3.073 2.258 2.258 0 0 0 .688-3.197 2.642 2.642 0 0 0 1.158-2.187Z"
      fill="#E81528"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.288 28.418a.726.726 0 0 0-.723.724c0 .398.326.724.723.724a.726.726 0 0 0 .722-.724.726.726 0 0 0-.722-.724Z"
      fill="#E81528"
    />
    <mask
      id="icon-policies-7_svg__a"
      style={{
        maskType: 'alpha'
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={46}
      height={46}>
      <path d="M0 0h45.91v46H0V0Z" fill="#fff" />
    </mask>
    <g mask="url(#icon-policies-7_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m43.188 23.255 1.186 3.144-2.098 2.623a.72.72 0 0 0-.157.485l.159 3.357-2.805 1.846a.72.72 0 0 0-.298.412l-.886 3.242-3.236.887a.72.72 0 0 0-.41.3L32.8 42.36l-3.35-.158a.718.718 0 0 0-.484.157l-2.618 2.102-3.139-1.189a.718.718 0 0 0-.508 0l-3.138 1.189-2.618-2.102a.719.719 0 0 0-.484-.157l-3.351.158-1.842-2.81a.72.72 0 0 0-.411-.299l-3.236-.887-.885-3.242a.722.722 0 0 0-.299-.412l-2.804-1.846.158-3.357a.72.72 0 0 0-.157-.485L1.536 26.4l1.186-3.144a.722.722 0 0 0 0-.51l-1.186-3.144 2.098-2.623a.721.721 0 0 0 .157-.485l-.158-3.357 2.804-1.846a.721.721 0 0 0 .299-.412l.885-3.242 3.236-.887a.72.72 0 0 0 .411-.3L13.11 3.64l3.351.158a.718.718 0 0 0 .484-.157l2.618-2.102L22.7 2.728a.718.718 0 0 0 .508 0l3.139-1.189 2.618 2.102c.136.11.308.166.483.157L32.8 3.64l1.842 2.81a.72.72 0 0 0 .411.299l3.236.887.886 3.242c.046.17.152.316.298.412l2.805 1.846-.159 3.357a.721.721 0 0 0 .157.485l2.098 2.623-1.186 3.144a.723.723 0 0 0 0 .51ZM44.63 23l1.235-3.274a.721.721 0 0 0-.111-.705l-2.185-2.732.165-3.496a.72.72 0 0 0-.323-.636l-2.92-1.922-.922-3.376a.72.72 0 0 0-.503-.504l-3.37-.924-1.918-2.925a.72.72 0 0 0-.635-.324l-3.49.165L26.927.158a.718.718 0 0 0-.703-.111l-3.268 1.237L19.687.047a.717.717 0 0 0-.703.111l-2.726 2.189-3.49-.165a.717.717 0 0 0-.635.324L10.216 5.43l-3.37.924a.72.72 0 0 0-.504.504l-.921 3.377-2.92 1.921a.72.72 0 0 0-.323.636l.164 3.496-2.184 2.732a.721.721 0 0 0-.111.705L1.28 23 .046 26.274a.721.721 0 0 0 .112.705l2.184 2.732-.164 3.496a.72.72 0 0 0 .323.636l2.92 1.921.921 3.377a.72.72 0 0 0 .504.504l3.37.924 1.917 2.925a.72.72 0 0 0 .635.324l3.49-.165 2.726 2.189c.198.159.466.202.703.111l3.268-1.237 3.268 1.237a.718.718 0 0 0 .704-.111l2.726-2.189 3.49.165a.719.719 0 0 0 .634-.324l1.918-2.925 3.37-.924a.72.72 0 0 0 .503-.504l.922-3.377 2.92-1.921a.72.72 0 0 0 .323-.636l-.165-3.496 2.184-2.732a.722.722 0 0 0 .112-.705L44.629 23Z"
        fill="#E81528"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.462 10.563a18.823 18.823 0 0 0-1.127-1.13.718.718 0 0 0-1.016.044.721.721 0 0 0 .044 1.018 17.492 17.492 0 0 1 1.04 1.043.717.717 0 0 0 1.016.043.721.721 0 0 0 .043-1.018ZM32.782 7.468a18.395 18.395 0 0 0-11.752-2.77A18.38 18.38 0 0 0 4.593 22.241a18.352 18.352 0 0 0 4.28 12.574.717.717 0 0 0 1.014.088.721.721 0 0 0 .087-1.015A16.914 16.914 0 0 1 6.03 22.3 16.939 16.939 0 0 1 21.18 6.13a16.95 16.95 0 0 1 10.83 2.552c.335.213.78.115.993-.22a.721.721 0 0 0-.22-.995ZM38.424 13.121a.718.718 0 0 0-.992-.22.72.72 0 0 0-.22.994 17.04 17.04 0 0 1 2.546 10.853 17 17 0 0 1-4.864 10.228 16.85 16.85 0 0 1-11.273 4.95 16.837 16.837 0 0 1-11.566-3.952.718.718 0 0 0-1.012.088.721.721 0 0 0 .087 1.014 18.262 18.262 0 0 0 12.55 4.29 18.283 18.283 0 0 0 12.231-5.371 18.445 18.445 0 0 0 5.278-11.099 18.487 18.487 0 0 0-2.765-11.775Z"
      fill="#E81528"
    />
  </svg>
);

export default SvgIconPolicies7;
