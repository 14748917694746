import * as React from 'react';

const SvgIconPeriodicReviews = (props) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.26 8.527c0 1.624-.527 2.494-1.76 2.494-1.247 0-1.774-.89-1.774-2.494 0-1.623.541-2.506 1.774-2.506 1.24 0 1.76.904 1.76 2.506Zm-2.274-.006c0 .863.055 1.39.514 1.39s.514-.527.514-1.404c0-.843-.062-1.377-.514-1.377-.459 0-.514.514-.514 1.39Z"
      fill="#7C7D80"
    />
    <path d="M8.322 6.904 10 6.021h.466v4.91h-1.24V7.57l-.904.479V6.904Z" fill="#7C7D80" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.782 2.415H.5v11.94h13.308V2.415H10.8V1h-1v1.415H4.782V1h-1v1.415Zm-2.282 1v9.94h11.308v-9.94H1.5ZM19.738 9.5a3.783 3.783 0 0 0-3.609 4.921l-6.098 6.098a1.813 1.813 0 1 0 2.564 2.565l6.15-6.15a3.783 3.783 0 0 0 4.29-5.51l-.325-.573-2.55 2.55a.485.485 0 1 1-.685-.686l2.537-2.537-.635-.306a3.77 3.77 0 0 0-1.639-.372Zm-2.783 3.783a2.783 2.783 0 0 1 3.277-2.74l-1.464 1.465a1.485 1.485 0 1 0 2.1 2.1l1.556-1.557a2.787 2.787 0 0 1-3.646 3.346l-.3-.11-6.59 6.59a.813.813 0 0 1-1.15-1.15l6.56-6.561-.129-.31a2.773 2.773 0 0 1-.214-1.073Z"
      fill="#7C7D80"
    />
  </svg>
);

export default SvgIconPeriodicReviews;
