import * as React from 'react';

const SvgGenericIcon = (props) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-icon="1" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 11.55v.9h-.008c-.231 6.27-5.272 11.31-11.542 11.542V24h-.9v-.008C5.28 23.76.24 18.72.008 12.45H0v-.9h.008C.24 5.28 5.28.24 11.55.008V0h.9v.008C18.72.24 23.76 5.28 23.992 11.55H24Zm-6.017.9h5.108a11.071 11.071 0 0 1-3.645 7.782l-3.27-3.924a5.982 5.982 0 0 0 1.807-3.858Zm-2.385 3.165a5.085 5.085 0 0 0 1.482-3.165h-4.12l2.638 3.165Zm-3.148-2.372 2.457 2.948a5.071 5.071 0 0 1-2.457.89v-3.838Zm.51-1.693h4.12a5.084 5.084 0 0 0-1.482-3.165L12.96 11.55Zm1.947-3.741-2.457 2.948V6.92c.908.079 1.748.396 2.457.889Zm1.269-.117a5.982 5.982 0 0 1 1.807 3.858h5.108a11.071 11.071 0 0 0-3.645-7.782l-3.27 3.924Zm2.579-4.5-3.27 3.923a5.968 5.968 0 0 0-3.035-1.098V.909c2.368.094 4.545.93 6.305 2.282Zm-7.205 7.565L9.093 7.81a5.071 5.071 0 0 1 2.457-.89v3.838ZM8.402 8.385l2.637 3.165H6.92a5.084 5.084 0 0 1 1.483-3.165Zm.113-1.27a5.968 5.968 0 0 1 3.035-1.098V.909c-2.368.094-4.545.93-6.305 2.282l3.27 3.924ZM4.554 3.768l3.27 3.924a5.982 5.982 0 0 0-1.807 3.858H.909a11.071 11.071 0 0 1 3.645-7.782Zm6.485 8.682H6.92a5.084 5.084 0 0 0 1.483 3.165l2.637-3.165Zm-1.946 3.741 2.457-2.948v3.837a5.071 5.071 0 0 1-2.457-.889Zm-1.269.117a5.982 5.982 0 0 1-1.807-3.858H.909a11.071 11.071 0 0 0 3.645 7.782l3.27-3.924Zm-2.579 4.5 3.27-3.923a5.968 5.968 0 0 0 3.035 1.098v5.108a11.046 11.046 0 0 1-6.305-2.282Zm10.24-3.923 3.27 3.924a11.047 11.047 0 0 1-6.305 2.282v-5.108a5.968 5.968 0 0 0 3.035-1.098Z"
      fill="#EB0A1E"
    />
  </svg>
);

export default SvgGenericIcon;
