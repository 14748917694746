import * as React from 'react';

const SvgIconTireMenu = (props) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.25 12C1.25 6.063 6.063 1.25 12 1.25S22.75 6.063 22.75 12h1C23.75 5.51 18.49.25 12 .25S.25 5.51.25 12 5.51 23.75 12 23.75c3.122 0 5.96-1.218 8.065-3.205l-.687-.727A10.711 10.711 0 0 1 12 22.75C6.063 22.75 1.25 17.937 1.25 12Z"
      fill="#7C7D80"
    />
    <path
      d="M8.009 4.262A8.73 8.73 0 0 0 3.7 9.37l-.953-.301A9.731 9.731 0 0 1 7.55 3.374l.459.888ZM18.488 17.803A8.67 8.67 0 0 0 20.705 12h1a9.67 9.67 0 0 1-2.471 6.47l-.746-.667Z"
      fill="#7C7D80"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 19.16A7.16 7.16 0 1 0 12 4.84a7.16 7.16 0 0 0 0 14.318Zm-.5-1.02v-4.156a2.03 2.03 0 0 1-.549-.227l-2.938 2.938a6.132 6.132 0 0 0 3.487 1.444Zm-4.194-2.152 2.938-2.938a2.034 2.034 0 0 1-.228-.55H5.861a6.132 6.132 0 0 0 1.445 3.488ZM5.86 11.5h4.155c.05-.195.127-.38.227-.549L7.305 8.013A6.132 6.132 0 0 0 5.861 11.5Zm2.151-4.194 2.938 2.938c.17-.101.354-.179.55-.228V5.861a6.132 6.132 0 0 0-3.488 1.445Zm7.975 9.389a6.133 6.133 0 0 1-3.487 1.444v-4.155c.195-.05.38-.127.549-.227l2.938 2.938Zm.707-.707-2.938-2.938c.101-.17.179-.354.228-.55h4.155a6.133 6.133 0 0 1-1.445 3.488Zm1.445-4.488h-4.155a2.033 2.033 0 0 0-.227-.549l2.938-2.938a6.131 6.131 0 0 1 1.444 3.487Zm-2.151-4.194-2.938 2.938a2.033 2.033 0 0 0-.55-.228V5.861a6.132 6.132 0 0 1 3.488 1.445ZM12 13.046a1.046 1.046 0 1 0 0-2.092 1.046 1.046 0 0 0 0 2.091Z"
      fill="#7C7D80"
    />
  </svg>
);

export default SvgIconTireMenu;
