import React from 'react';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Atom Title
 *
 * The title component
 */
function Title (props) {

  const {
    className = "",
    children,
    level = 3,
    color = null,
    variant = null,
    isUppercase = null,
    ...other
  } = props;

  const Tag = function H ({children, ...other}) {
    switch (level) {
    case 1: return <h1 {...other}>{children}</h1>;
    case 2: return <h2 {...other}>{children}</h2>;
    default:
    case 3: return <h3 {...other}>{children}</h3>;
    case 4: return <h4 {...other}>{children}</h4>;
    case 5: return <h5 {...other}>{children}</h5>;
    case 6: return <h6 {...other}>{children}</h6>;
    }
  };

  return (<>
    <Tag
      className={`${css["atom__title-container"]} ${className}`}
      data-variant={variant}
      data-color ={color}
      data-is-uppercase={isUppercase ? true : null}
      {...other}
    >
      {children}
    </Tag>
  </>);
}

export default Title;
