import * as React from 'react';

const SvgIconPinMap = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.75866 2.79313L8.6576 2.77629C8.2222 2.70372 7.7778 2.70372 7.3424 2.77629L7.24134 2.79313C5.37091 3.10487 4 4.72317 4 6.61941C4 7.94832 4.43102 9.24138 5.22837 10.3045L8 14L10.7716 10.3045C11.569 9.24138 12 7.94832 12 6.61941C12 4.72317 10.6291 3.10487 8.75866 2.79313ZM8 8.66669C9.10457 8.66669 10 7.77126 10 6.66669C10 5.56212 9.10457 4.66669 8 4.66669C6.89543 4.66669 6 5.56212 6 6.66669C6 7.77126 6.89543 8.66669 8 8.66669Z"
      fill="#00708D"
    />
    <path
      d="M8.6576 2.77629L8.5754 3.26948L8.5754 3.26948L8.6576 2.77629ZM8.75866 2.79313L8.84086 2.29993L8.84086 2.29993L8.75866 2.79313ZM7.3424 2.77629L7.4246 3.26948H7.4246L7.3424 2.77629ZM7.24134 2.79313L7.15914 2.29993L7.15914 2.29993L7.24134 2.79313ZM5.22837 10.3045L4.82837 10.6045L4.82837 10.6045L5.22837 10.3045ZM8 14L7.6 14.3L8 14.8334L8.4 14.3L8 14ZM10.7716 10.3045L10.3716 10.0045V10.0045L10.7716 10.3045ZM8.5754 3.26948L8.67646 3.28633L8.84086 2.29993L8.7398 2.28309L8.5754 3.26948ZM7.4246 3.26948C7.80557 3.20599 8.19443 3.20599 8.5754 3.26948L8.7398 2.28309C8.24998 2.20145 7.75002 2.20145 7.2602 2.28309L7.4246 3.26948ZM7.32354 3.28633L7.4246 3.26948L7.2602 2.28309L7.15914 2.29993L7.32354 3.28633ZM4.5 6.61941C4.5 4.96759 5.6942 3.55788 7.32354 3.28633L7.15914 2.29993C5.04762 2.65186 3.5 4.47875 3.5 6.61941H4.5ZM5.62837 10.0045C4.89593 9.02793 4.5 7.84013 4.5 6.61941H3.5C3.5 8.0565 3.96611 9.45483 4.82837 10.6045L5.62837 10.0045ZM8.4 13.7L5.62837 10.0045L4.82837 10.6045L7.6 14.3L8.4 13.7ZM10.3716 10.0045L7.6 13.7L8.4 14.3L11.1716 10.6045L10.3716 10.0045ZM11.5 6.61941C11.5 7.84013 11.1041 9.02793 10.3716 10.0045L11.1716 10.6045C12.0339 9.45483 12.5 8.0565 12.5 6.61941H11.5ZM8.67646 3.28633C10.3058 3.55788 11.5 4.96759 11.5 6.61941H12.5C12.5 4.47875 10.9524 2.65186 8.84086 2.29993L8.67646 3.28633ZM9.5 6.66669C9.5 7.49511 8.82843 8.16669 8 8.16669V9.16669C9.38071 9.16669 10.5 8.0474 10.5 6.66669H9.5ZM8 5.16669C8.82843 5.16669 9.5 5.83826 9.5 6.66669H10.5C10.5 5.28598 9.38071 4.16669 8 4.16669V5.16669ZM6.5 6.66669C6.5 5.83826 7.17157 5.16669 8 5.16669V4.16669C6.61929 4.16669 5.5 5.28598 5.5 6.66669H6.5ZM8 8.16669C7.17157 8.16669 6.5 7.49511 6.5 6.66669H5.5C5.5 8.0474 6.61929 9.16669 8 9.16669V8.16669Z"
      fill="#00708D"
    />
  </svg>
);

export default SvgIconPinMap;
