import * as React from 'react';

const SvgIconGoogleMaps = (props) => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.39697 8.27769L21.397 4.27769L21.603 5.25624L13.1463 7.03659L18.9642 21.5813L18.0357 21.9527L12.153 7.24572L2.60298 9.25624L2.39697 8.27769ZM8.99998 12.267C7.44811 12.267 5.99998 13.4785 5.99998 15.296C5.99998 16.2035 6.44989 17.0075 7.05703 17.6771C7.6624 18.3449 8.38455 18.8342 8.82996 19.1011L8.83114 19.1018C8.8821 19.1325 8.94047 19.1487 8.99998 19.1487C9.05948 19.1487 9.11786 19.1325 9.16882 19.1018L9.17 19.1011C9.61541 18.8342 10.3376 18.3449 10.943 17.6772C11.5501 17.0076 12 16.204 12 15.297C12 13.4728 10.6089 12.267 8.99998 12.267ZM4.99998 15.296C4.99998 12.8794 6.94384 11.267 8.99998 11.267C11.1251 11.267 13 12.8851 13 15.297C13 16.5479 12.3798 17.5813 11.6838 18.349C10.9859 19.1186 10.1715 19.6667 9.68396 19.9589C9.47744 20.0831 9.24099 20.1487 8.99998 20.1487C8.7587 20.1487 8.52199 20.0829 8.31532 19.9585C7.82765 19.6662 7.01373 19.1182 6.31618 18.3488C5.62006 17.581 4.99998 16.5474 4.99998 15.296Z"
      fill="#1F1F1F"
    />
    <path
      d="M9 16.267C9.55228 16.267 10 15.8193 10 15.267C10 14.7147 9.55228 14.267 9 14.267C8.44772 14.267 8 14.7147 8 15.267C8 15.8193 8.44772 16.267 9 16.267Z"
      fill="#1F1F1F"
    />
    <rect x="2.5" y="2.76697" width="19" height="19" rx="1.5" stroke="#1F1F1F" />
  </svg>
);

export default SvgIconGoogleMaps;
