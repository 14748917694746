import * as React from 'react';

interface SvgIconWhatsAppProps extends React.SVGProps<SVGSVGElement> {
  color?: string;
}

const SvgIconWhatsApp: React.FC<SvgIconWhatsAppProps> = ({ color = '#000', ...rest }) => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Z" fill={color} />
    <path
      d="M7.774 4.005A3.97 3.97 0 0 0 4.432 9.77l-.421 2.044a.154.154 0 0 0 .186.182l2.004-.475a3.968 3.968 0 1 0 1.573-7.516v0Z"
      stroke="#fff"
      strokeWidth={0.8}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m9.894 8.828-.77-.22a.286.286 0 0 0-.283.074l-.188.192a.28.28 0 0 1-.304.064c-.364-.147-1.128-.827-1.324-1.167a.28.28 0 0 1 .022-.31l.165-.213a.286.286 0 0 0 .035-.291l-.323-.731a.287.287 0 0 0-.448-.103c-.214.182-.469.457-.5.763-.054.538.177 1.217 1.05 2.032 1.009.942 1.817 1.067 2.343.939.298-.073.537-.362.687-.6a.286.286 0 0 0-.162-.429Z"
      fill="#fff"
    />
  </svg>
);

export default SvgIconWhatsApp;
