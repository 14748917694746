import * as React from 'react';

const SvgIconWaze = (props) => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.7477 21.847C10.5462 22.763 10.0514 23.4407 9.24366 23.8606C8.21684 24.3919 7.17046 24.3743 6.1984 23.7513C5.18919 23.1107 4.74912 22.14 4.82344 20.9486C4.84105 20.7474 4.78628 20.6732 4.62199 20.5814C2.84217 19.6479 1.43201 18.3471 0.45995 16.5893C0.2761 16.2417 0.129412 15.8745 0.0374872 15.4898C-0.0915986 14.9585 0.111809 14.7027 0.661402 14.648C1.32248 14.5738 1.90923 14.3179 2.20261 13.6949C2.33169 13.4195 2.40406 13.0914 2.40406 12.7789C2.44122 11.9918 2.40406 11.2047 2.44122 10.4157C2.64267 7.37473 3.90811 4.8494 6.21992 2.87093C8.34592 1.04286 10.8396 0.183502 13.6443 0.273343C18.5417 0.456932 22.741 3.9002 23.7306 8.71649C24.6283 13.0758 23.2729 16.7202 19.8052 19.558C19.6038 19.7221 19.3828 19.8881 19.1441 20.0346C19.0151 20.1264 18.9799 20.1986 19.0346 20.3451C19.4923 21.7376 18.8508 23.2747 17.5677 23.9349C15.8251 24.7962 13.8086 23.9173 13.2766 22.0482C13.259 21.974 13.1671 21.8841 13.1123 21.8841C12.3437 21.8294 11.5535 21.847 10.7477 21.847ZM10.7849 20.6205C11.6102 20.6205 12.4356 20.6576 13.261 20.6029C14.012 20.5658 14.8022 20.4935 15.5356 20.3099C21.3679 18.8822 24.4856 12.4527 21.9351 7.01536C20.3939 3.73615 17.7535 1.86705 14.1411 1.4823C11.3716 1.18934 8.89551 2.01353 6.80472 3.88067C4.75108 5.73024 3.6871 8.03682 3.61473 10.8024C3.59713 11.443 3.61473 12.1031 3.61473 12.7437C3.59517 14.1382 2.80697 15.2183 1.46721 15.6382C1.44961 15.6382 1.41245 15.6753 1.39289 15.6753C1.90728 17.0307 3.99612 19.0268 5.29871 19.5209C6.9338 17.2866 10.2333 18.0756 10.7849 20.6205Z"
      fill="#1F1F1F"
    />
    <path
      d="M15.1837 16.5814C12.5856 16.4499 10.87 15.4149 9.76921 13.3472C9.504 12.8403 9.74809 12.3357 10.2527 12.2254C10.5602 12.1596 10.8465 12.3803 11.0671 12.7323C11.3534 13.1735 11.6398 13.6124 12.0129 13.9645C14.0806 15.9219 17.4697 15.307 18.7699 12.7769C18.9248 12.4694 19.122 12.2277 19.4952 12.2277C20.0444 12.2488 20.3542 12.8004 20.1124 13.305C19.4295 14.692 18.3522 15.7036 16.8994 16.1871C16.261 16.4054 15.6015 16.4922 15.1837 16.5814Z"
      fill="#1F1F1F"
    />
    <path
      d="M10.5836 9.36201C9.79033 9.36201 9.1543 8.72363 9.1543 7.93269C9.1543 7.14175 9.79268 6.50337 10.5836 6.50337C11.3746 6.50337 12.0129 7.14175 12.0129 7.93269C12.0153 8.70251 11.3769 9.36201 10.5836 9.36201Z"
      fill="#1F1F1F"
    />
    <path
      d="M20.664 7.90922C20.664 8.70251 20.0256 9.33854 19.2558 9.36201C18.4625 9.36201 17.8265 8.74475 17.8265 7.93269C17.8265 7.13941 18.4437 6.50337 19.2347 6.4799C20.0045 6.4799 20.6405 7.11828 20.664 7.90922Z"
      fill="#1F1F1F"
    />
  </svg>
);

export default SvgIconWaze;
