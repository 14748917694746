import * as React from 'react';

const SvgFacebookIcon = (props) => (
  <svg viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg" data-icon="1" {...props}>
    <path
      d="M6.694 3.431H9V0H6.272C2.982.14 2.306 1.997 2.25 3.938v1.715H0V9h2.25v9h3.375V9h2.784l.535-3.347H5.625v-1.04A1.114 1.114 0 0 1 6.694 3.43Z"
      fill="#fff"
      color="#fff"
    />
  </svg>
);

export default SvgFacebookIcon;
