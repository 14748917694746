import * as React from 'react';

const SvgInstagramIcon = (props) => (
  <svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-icon="1" {...props}>
    <path
      d="M11.257 0H4.776A4.777 4.777 0 0 0 0 4.766v6.468A4.777 4.777 0 0 0 4.776 16h6.481a4.777 4.777 0 0 0 4.778-4.766V4.766A4.777 4.777 0 0 0 11.257 0Zm3.164 11.234a3.158 3.158 0 0 1-3.164 3.156H4.776a3.161 3.161 0 0 1-3.164-3.156V4.766A3.16 3.16 0 0 1 4.776 1.61h6.481a3.16 3.16 0 0 1 3.164 3.157v6.468Z"
      fill="#fff"
      color="#fff"
    />
    <path
      d="M8.018 3.862A4.138 4.138 0 1 0 12.164 8a4.148 4.148 0 0 0-4.146-4.138Zm0 6.668a2.53 2.53 0 1 1 .011-5.059 2.53 2.53 0 0 1-.011 5.059Zm5.148-6.637a.994.994 0 1 1-1.988.004.994.994 0 0 1 1.988-.004Z"
      fill="#fff"
      color="#fff"
    />
  </svg>
);

export default SvgInstagramIcon;
