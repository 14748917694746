import * as React from 'react';

const SvgPlayIcon = (props) => {
  return (
    <svg
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-icon="1"
      {...props}>
      <rect width={96} height={96} rx={48} fill="#fff" />
      <path d="m36 32 28 16-28 16V32Z" fill="#EB0A1E" color="#EB0A1E" />
    </svg>
  );
};

export default SvgPlayIcon;
