import * as React from 'react';

const SvgIconPolicies2 = (props) => (
  <svg viewBox="0 0 46 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#icon-policies-2_svg__a)" fillRule="evenodd" clipRule="evenodd" fill="#E81528">
      <path d="M25.953 18.058a.903.903 0 0 0-.179.666l.422 3.222-3.005 1.243a.904.904 0 0 0-.489.489l-1.244 3.003-3.224-.422a.904.904 0 0 0-.666.178l-2.583 1.976-2.582-1.976a.904.904 0 0 0-.667-.178l-3.223.422-1.244-3.003a.904.904 0 0 0-.49-.49l-3.004-1.243.422-3.221a.902.902 0 0 0-.179-.666l-1.977-2.582 1.977-2.58c.145-.19.21-.43.179-.667l-.422-3.222 3.004-1.243a.903.903 0 0 0 .49-.489l1.244-3.003 3.224.422a.904.904 0 0 0 .666-.178l2.582-1.976 2.583 1.976c.19.145.43.21.666.178l3.224-.422 1.244 3.003a.903.903 0 0 0 .49.49l3.004 1.243-.422 3.222a.903.903 0 0 0 .179.666l1.977 2.58-1.977 2.582ZM15.469 35.8a.904.904 0 0 0-.968 0l-4.903 3.108V28.36l2.003-.262 2.835 2.17a.903.903 0 0 0 1.099 0l2.835-2.17 2.003.263v10.547L15.47 35.8Zm14.316-20.872-2.17-2.834.463-3.539a.903.903 0 0 0-.55-.951l-3.3-1.365-1.365-3.298a.903.903 0 0 0-.952-.55l-3.541.464-2.835-2.17a.904.904 0 0 0-1.099 0l-2.835 2.17-3.54-.463a.904.904 0 0 0-.953.55L5.742 6.239 2.443 7.604a.903.903 0 0 0-.55.951l.463 3.54-2.17 2.833a.903.903 0 0 0 0 1.097l2.17 2.834-.463 3.539a.903.903 0 0 0 .55.951l3.3 1.365 1.365 3.298a.902.902 0 0 0 .683.544V40.55a.903.903 0 0 0 1.387.763l5.808-3.681 5.807 3.681a.904.904 0 0 0 1.387-.762V28.556a.904.904 0 0 0 .683-.544l1.366-3.297 3.299-1.366a.903.903 0 0 0 .55-.951l-.463-3.539 2.17-2.834a.903.903 0 0 0 0-1.097Z" />
      <path d="M9.466 9.65a.9.9 0 0 0 .573-.205 7.818 7.818 0 0 1 4.947-1.767.904.904 0 1 0 0-1.806A9.632 9.632 0 0 0 8.892 8.05a.903.903 0 0 0 .574 1.601Z" />
      <path d="M18.208 6.425a.904.904 0 0 0-.606 1.702 7.815 7.815 0 0 1 5.186 7.35c0 4.3-3.5 7.798-7.803 7.798-4.302 0-7.803-3.498-7.803-7.798 0-1.53.443-3.01 1.28-4.282a.903.903 0 1 0-1.509-.993 9.562 9.562 0 0 0-1.578 5.275c0 5.296 4.311 9.604 9.61 9.604 5.3 0 9.61-4.308 9.61-9.604a9.625 9.625 0 0 0-6.387-9.052Z" />
    </g>
    <defs>
      <clipPath id="icon-policies-2_svg__a">
        <path fill="#fff" transform="translate(0 .5)" d="M0 0h46v41H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgIconPolicies2;
