import * as React from 'react';

const SvgOn2Icon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 333.3 333.3"
    style={{
      enableBackground: 'new 0 0 333.3 333.3'
    }}
    xmlSpace="preserve"
    data-icon="1"
    {...props}>
    <path
      className="on2-icon_svg__st0"
      d="m289.5 115.9-68.5 43V73.1l-67.5 42.3v33l39.5-24.8v86l109.8-68.8c14.2 75.2-35.2 147.7-110.3 162-59.1 11.2-118.6-17-147.4-69.7L21.2 248c45 80.2 146.6 108.8 226.9 63.7 75.4-42.3 105.9-135.2 70.3-214l-28.9 18.2z"
    />
    <path
      className="on2-icon_svg__st0"
      d="m43.8 217.4 68.5-43v85.8l67.5-42.3v-33l-39.5 24.7v-86L30.5 192.4C16.3 117.2 65.7 44.8 140.9 30.5c59-11.2 118.5 17 147.3 69.6L312 85.2c-44.9-80.3-146.4-109-226.8-64C9.7 63.5-20.9 156.6 14.9 235.5l28.9-18.1z"
    />
  </svg>
);

export default SvgOn2Icon;
