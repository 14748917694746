import * as React from 'react';

const SvgLinkedinIcon = (props) => (
  <svg viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg" data-icon="1" {...props}>
    <path
      d="M.287 4.579h3.25V14H.287V4.579ZM11.925 4.267c-1.425 0-2.275.503-3.175 1.606V4.58H5.5V14h3.275V8.894c0-1.08.575-2.134 1.875-2.134s2.075 1.055 2.075 2.11V14H16V8.654c0-3.716-2.625-4.387-4.075-4.387ZM2 0C1.023 0 .25.786.25 1.75S1.023 3.5 2 3.5s1.75-.786 1.75-1.75S2.977 0 2 0Z"
      fill="#fff"
      color="#fff"
    />
  </svg>
);

export default SvgLinkedinIcon;
