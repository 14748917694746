import * as React from 'react';

const SvgIconPolicies9 = (props) => (
  <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.94 11.606c-.565-5.598-4.886-9.254-5.07-9.407a.86.86 0 0 0-1.09-.009c-.044.036-4.497 3.686-5.052 9.403-.038.389-.056.782-.056 1.17a.86.86 0 0 0 .86.858h.001a.86.86 0 0 0 .858-.86 10.253 10.253 0 0 1 .212-2.086l2.757 2.757a.844.844 0 0 0 .117.096v2.093a.86.86 0 1 0 1.718 0v-4.436l2.317-2.317c.349.888.612 1.864.718 2.91.034.332.051.668.052 1.001A.86.86 0 1 0 38 12.776c-.001-.39-.022-.783-.06-1.17Zm-4.745-2.852V5.01a15.253 15.253 0 0 1 1.53 2.213l-1.53 1.53Zm-1.719 2.056v.307l-2.314-2.314c.636-1.614 1.553-2.926 2.314-3.838v5.845Z"
      fill="#E81528"
    />
    <path d="M45.142 44.281h-.01a.86.86 0 0 0 0 1.719h.01a.86.86 0 0 0 0-1.719Z" fill="#E81528" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.196 44.281h9.138a.86.86 0 1 1 0 1.719H2.858a.86.86 0 0 1 0-1.719h28.618v-6.719l-1.783-1.782a.86.86 0 0 1 1.216-1.216l.567.567v-7.617l-1.065-1.069c-.633.497-1.678 1.111-3.03 1.169-.07.003-.14.004-.21.004-1.576 0-3.128-.756-4.618-2.251-4.407-4.422-3.877-10.836-3.852-11.107a.86.86 0 0 1 .765-.776c.065-.008 6.59-.648 11.088 3.864.817.82 1.41 1.659 1.782 2.51.37-.852.964-1.69 1.782-2.51 4.497-4.512 11.022-3.871 11.087-3.864a.86.86 0 0 1 .765.776c.025.27.556 6.685-3.852 11.107-1.49 1.495-3.042 2.251-4.617 2.251-.07 0-.14-.001-.21-.004-1.352-.058-2.398-.672-3.031-1.168l-1.065 1.068v6.168a16.179 16.179 0 0 0 2.268-4.293.86.86 0 1 1 1.623.567 17.918 17.918 0 0 1-3.891 6.402v2.35l.051-.051a.86.86 0 0 1 1.215 1.215l-1.266 1.267v3.142ZM30.4 23.998c.309-.44.647-1.098.677-1.902.04-1.082-.482-2.203-1.547-3.335v4.365l.87.872Zm-6.629.155c1.201 1.206 2.39 1.792 3.537 1.744.795-.034 1.446-.372 1.88-.68l-3.649-3.66h-3.647c.469.905 1.08 1.794 1.88 2.596Zm.055-4.316L20.46 16.46c.088.946.285 2.134.705 3.377h2.661Zm3.985 1.564v-4.097c-2.13-1.439-4.508-1.92-6.147-2.07L27.81 21.4Zm7.332 1.725V18.76c-1.072 1.139-1.592 2.266-1.545 3.354.034.796.369 1.448.675 1.884l.87-.873Zm7.638-1.57h-3.647l-3.648 3.66c.438.31 1.095.652 1.898.681 1.14.041 2.323-.545 3.518-1.744.8-.802 1.41-1.691 1.879-2.597Zm.228-6.323c-1.639.15-4.017.632-6.147 2.07v4.098l6.147-6.168Zm-2.162 4.604h2.661c.42-1.243.617-2.43.705-3.377l-3.366 3.377Z"
      fill="#E81528"
    />
    <path
      d="m25.415 6.764-.01-.003a.86.86 0 0 0-.513 1.64l.019.006a.859.859 0 1 0 .504-1.643Z"
      fill="#E81528"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m29.36 31.28.903.211a.86.86 0 0 1-.392 1.674l-.902-.211c-2.139-.5-4.325.6-5.198 2.615l-2.023 4.669c2.311-.241 4.549-.97 6.552-2.147a.86.86 0 1 1 .87 1.483 18.034 18.034 0 0 1-9.123 2.472 18.007 18.007 0 0 1-4.708-.624C7.664 39.346 2 32.322 2 23.999c0-6.526 3.482-12.253 8.685-15.423a.856.856 0 0 1 .18-.107 17.936 17.936 0 0 1 9.182-2.517c.564 0 1.133.027 1.692.079a.86.86 0 1 1-.159 1.711 16.633 16.633 0 0 0-1.533-.07c-2.707 0-5.263.662-7.513 1.833l3.528 4.238a3.398 3.398 0 0 1 .147 4.159l-1.853 2.578a1.652 1.652 0 0 0-.131 1.747c.293.572.852.914 1.495.914h2.051c1.08 0 2.092.579 2.638 1.511.43.732.535 1.618.29 2.43a3.067 3.067 0 0 1-1.585 1.865l-.864.422a5.81 5.81 0 0 0-3.024 6.913l1.084 3.613c1.135.267 2.316.414 3.527.43l2.357-5.44a6.253 6.253 0 0 1 7.166-3.605ZM11.035 10.39C6.629 13.318 3.719 18.325 3.719 24c0 6.994 4.422 12.975 10.617 15.296l-.756-2.52a7.523 7.523 0 0 1 3.916-8.951l.863-.422c.34-.166.586-.456.695-.817a1.326 1.326 0 0 0-.127-1.064 1.345 1.345 0 0 0-1.156-.662H15.72a3.385 3.385 0 0 1-3.025-1.849 3.385 3.385 0 0 1 .266-3.535l1.853-2.577a1.68 1.68 0 0 0-.072-2.057l-3.707-4.452Z"
      fill="#E81528"
    />
  </svg>
);

export default SvgIconPolicies9;
